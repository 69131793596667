import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from 'core/request';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({baseUrl: `${process.env.REACT_APP_SERVER_URL}`}),
  tagTypes: [
    'Dealers',
    'CompanyOwners',
    'CompanyOwnersEdit',
    'Clients',
    'ClientsEdit',
    'Companies',
    'Branches',
    'Stations',
    'StationEdit',
    'Manifests',
    'SystemLogs',
    'DataCollector',
    'Reports',
    'Profile',
    'Dictionarys',
  ],
  endpoints: () => ({}),
});
