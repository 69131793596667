import React, {FC} from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import {DateTimePicker} from '@mui/x-date-pickers';
import {
  CalendarIcon,
  CheckedCheckboxIcon,
  CloseIcon,
  FilterIcon,
  SelectPopupIcon,
  UncheckedCheckboxIcon,
} from 'assets/icons';
import dayjs from 'dayjs';
import {
  calendarStyles,
  chipStyles,
  searchAndSelect,
  selectRowsPaperProps,
} from 'styles/MUIStyles/common';
import search from 'assets/icons/search.svg';
import {pageSizes} from 'core/constants';
import MenuItem from '@mui/material/MenuItem';
import styles from '../../Backups.module.scss';
import {IMetadata} from 'store/models/common';
import {clearFiltersButton} from 'styles/MUIStyles/buttons';
import {IGetBackupFileTypes} from 'store/models/enums';
import {StationsListItem} from 'store/models/stations';
import {IGetCompaniesListItem} from 'store/models/companies';
import {IGetBranchesListItem} from 'store/models/branches';

interface Props {
  search: string;
  setSearch: (search: string) => void;
  mainBackupsStartDate: string | null;
  setMainBackupsStartDate: (date: string | null) => void;
  mainBackupsFinishDate: string | null;
  setMainBackupsFinishDate: (data: string | null) => void;
  mainBackupsPageSize: number;
  setMainBackupsPageSize: (pageSize: number) => void;
  stationsForMainBackups: StationsListItem[];
  selectedStationsForMainBackups: StationsListItem[];
  setSelectedStationsForMainBackups: (stations: StationsListItem[]) => void;
  isMainBackupsShowFilterButtonOpen: boolean;
  setIsMainBackupsShowFilterButtonOpen: (
    prevState: (isOpen: boolean) => boolean,
  ) => void;
  metaData: IMetadata | null;
  clearMainBackupsFilters: () => void;
  backupsFileTypes: IGetBackupFileTypes[];
  mainBackupFileType: {id: number; name: string; description: string} | null;
  setMainBackupFileType: (
    fileType: {id: number; name: string; description: string} | null,
  ) => void;
  companies: IGetCompaniesListItem[];
  selectedCompanies: IGetCompaniesListItem[];
  setSelectedCompanies: (companies: IGetCompaniesListItem[]) => void;
  branches: IGetBranchesListItem[];
  selectedBranches: IGetBranchesListItem[];
  setSelectedBranches: (companies: IGetBranchesListItem[]) => void;
  setShouldSaveAllBackupsFileType: (shouldSave: boolean) => void;
}

const MainBackupsTopToolbar: FC<Props> = (props) => {
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          justifyContent: 'space-between',
          marginBottom: '14px',
        }}
      >
        <Box
          sx={{
            width: '80%',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <div className={styles.backupsTopToolbarSearch}>
            <input
              name="allBackupsSearch"
              type="text"
              className="input"
              placeholder="Search"
              autoComplete="off"
              value={props.search}
              onChange={(e) => props.setSearch(e.target.value)}
            />
            <img className={styles.searchIcon} src={search} alt="search icon" />
          </div>
          <Button
            variant="text"
            startIcon={
              <FilterIcon
                style={{
                  width: '16px',
                  height: '16px',
                  filter:
                    'invert(25%) sepia(81%) saturate(1632%) hue-rotate(213deg) brightness(95%) contrast(95%)',
                }}
              />
            }
            sx={clearFiltersButton}
            onClick={() => {
              props.setIsMainBackupsShowFilterButtonOpen(
                (prevState) => !prevState,
              );
            }}
          >
            {props.isMainBackupsShowFilterButtonOpen
              ? 'Hide filters'
              : 'Show filters'}
          </Button>
        </Box>
        <div className={styles.topToolbarSelectContainer}>
          {props.metaData && (
            <>
              <span>Show rows:</span>
              <FormControl size="small">
                <Select
                  name="stationsPageCount"
                  value={+props.mainBackupsPageSize}
                  onChange={(e) => {
                    props.setMainBackupsPageSize(+e.target.value);
                  }}
                  IconComponent={(props) => <SelectPopupIcon {...props} />}
                  MenuProps={{
                    MenuListProps: {disablePadding: true},
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    PaperProps: selectRowsPaperProps,
                  }}
                >
                  {pageSizes.map((page) => {
                    return (
                      <MenuItem key={page} value={page}>
                        {page}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <span>{`${props?.metaData?.firstItemOnPage}-${props?.metaData?.lastItemOnPage} of ${props?.metaData?.totalItemCount}`}</span>
            </>
          )}
        </div>
      </Box>

      {props.isMainBackupsShowFilterButtonOpen && (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              marginBottom: '12px',
            }}
          >
            <DateTimePicker
              inputFormat="DD.MM.YY HH:mm"
              ampm={false}
              minutesStep={1}
              value={props.mainBackupsStartDate}
              components={{
                OpenPickerIcon: CalendarIcon,
              }}
              onChange={(newValue) => {
                if (dayjs(newValue).isValid()) {
                  props.setMainBackupsStartDate(
                    dayjs(newValue).format('YYYY-MM-DDTHH:mm:00.000'),
                  );
                }

                if (newValue === null) {
                  props.setMainBackupsStartDate(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{...calendarStyles, width: '15%'}}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'Select start date',
                    sx: {
                      '&::placeholder': {
                        color: '#212121',
                        opacity: '.7',
                      },
                    },
                    autoComplete: 'off',
                  }}
                />
              )}
            />
            <DateTimePicker
              inputFormat="DD.MM.YY HH:mm"
              ampm={false}
              minutesStep={1}
              minDate={props.mainBackupsStartDate}
              value={props.mainBackupsFinishDate}
              components={{
                OpenPickerIcon: CalendarIcon,
              }}
              onChange={(newValue) => {
                if (dayjs(newValue).isValid()) {
                  props.setMainBackupsFinishDate(
                    dayjs(newValue).format('YYYY-MM-DDTHH:mm:00.000'),
                  );
                }

                if (newValue === null) {
                  props.setMainBackupsFinishDate(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{...calendarStyles, width: '15%'}}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'Select end date',
                    sx: {
                      '&::placeholder': {
                        color: '#212121',
                        opacity: '.7',
                      },
                    },
                    autoComplete: 'off',
                  }}
                />
              )}
            />
            <Autocomplete
              options={props.backupsFileTypes || []}
              loading={false}
              popupIcon={<SelectPopupIcon />}
              PaperComponent={(props) => (
                <Paper
                  sx={{
                    borderRadius: '12px',
                    boxShadow: '0px 4px 20px 0px #4444441F',
                    marginTop: '8px',
                    cursor: 'pointer',
                  }}
                  {...props}
                />
              )}
              sx={{width: '10%'}}
              value={props.mainBackupFileType}
              ListboxProps={{
                style: {maxHeight: '200px'},
                className: 'autoCompleteListBox',
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === '' ? true : option.id === value.id
              }
              getOptionLabel={(option) => (option?.name ? option.name : '')}
              onChange={(_, value) => {
                if (value) {
                  props.setMainBackupFileType(value);
                } else {
                  props.setMainBackupFileType(null);
                  props.setShouldSaveAllBackupsFileType(false);
                }
              }}
              renderOption={(props: any, option) => (
                <span {...props} className={styles.dropDownItem}>
                  {option?.name}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Type"
                  sx={searchAndSelect}
                />
              )}
            />

            <>
              <Autocomplete
                multiple
                limitTags={1}
                options={props.companies || []}
                loading={false}
                size="small"
                popupIcon={<SelectPopupIcon />}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      borderRadius: '12px',
                      boxShadow: '0px 4px 20px 0px #4444441F',
                      marginTop: '8px',
                      cursor: 'pointer',
                    }}
                    {...props}
                  />
                )}
                sx={{width: '20%'}}
                value={props.selectedCompanies}
                ListboxProps={{
                  style: {maxHeight: '300px'},
                  className: 'autoCompleteListBox',
                }}
                isOptionEqualToValue={(option, value) =>
                  value.name === '' ? true : option.id === value.id
                }
                getOptionLabel={(option) => (option?.name ? option.name : '')}
                onChange={(_, value) => {
                  props.setSelectedBranches([]);

                  if (value) {
                    props.setSelectedCompanies(value);
                  }
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({index})}
                      size="small"
                      label={option.name}
                      sx={chipStyles}
                      deleteIcon={
                        <CloseIcon style={{width: '24px', height: '24px'}} />
                      }
                    />
                  ))
                }
                renderOption={(props: any, option, {selected}) => (
                  <span {...props} className={styles.dropDownItem}>
                    <Checkbox
                      icon={<UncheckedCheckboxIcon />}
                      checkedIcon={<CheckedCheckboxIcon />}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </span>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Select company"
                    sx={searchAndSelect}
                  />
                )}
              />
              <Autocomplete
                multiple
                limitTags={1}
                options={props.branches || []}
                loading={false}
                size="small"
                popupIcon={<SelectPopupIcon />}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      borderRadius: '12px',
                      boxShadow: '0px 4px 20px 0px #4444441F',
                      marginTop: '8px',
                      cursor: 'pointer',
                    }}
                    {...props}
                  />
                )}
                sx={{width: '20%'}}
                value={props.selectedBranches}
                ListboxProps={{
                  style: {maxHeight: '300px'},
                  className: 'autoCompleteListBox',
                }}
                isOptionEqualToValue={(option, value) =>
                  value.name === '' ? true : option.id === value.id
                }
                getOptionLabel={(option) => (option?.name ? option.name : '')}
                onChange={(_, value) => {
                  if (value) {
                    props.setSelectedBranches(value);
                  }
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({index})}
                      size="small"
                      label={option.name}
                      sx={chipStyles}
                      deleteIcon={
                        <CloseIcon style={{width: '24px', height: '24px'}} />
                      }
                    />
                  ))
                }
                renderOption={(props: any, option, {selected}) => (
                  <span {...props} className={styles.dropDownItem}>
                    <Checkbox
                      icon={<UncheckedCheckboxIcon />}
                      checkedIcon={<CheckedCheckboxIcon />}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </span>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Select branch"
                    sx={searchAndSelect}
                  />
                )}
              />
            </>
            <Autocomplete
              multiple
              limitTags={1}
              options={props.stationsForMainBackups || []}
              value={props.selectedStationsForMainBackups || []}
              loading={false}
              size="small"
              popupIcon={<SelectPopupIcon />}
              PaperComponent={(props) => (
                <Paper
                  sx={{
                    borderRadius: '12px',
                    boxShadow: '0px 4px 20px 0px #4444441F',
                    marginTop: '12px',
                    cursor: 'pointer',
                  }}
                  {...props}
                />
              )}
              sx={{
                width: '20%',
              }}
              disableCloseOnSelect
              ListboxProps={{
                style: {maxHeight: '200px'},
                className: 'autoCompleteListBox',
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === '' ? true : option.id === value.id
              }
              getOptionLabel={(option) =>
                `${option.name}/${option.posChassicNumber}`
              }
              onChange={(_, value) => {
                if (value) {
                  props.setSelectedStationsForMainBackups(value);
                }
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({index})}
                    size="small"
                    label={`${option.name}/${option.posChassicNumber}`}
                    sx={chipStyles}
                    deleteIcon={
                      <CloseIcon style={{width: '24px', height: '24px'}} />
                    }
                  />
                ))
              }
              renderOption={(props: any, option, {selected}) => (
                <span {...props} className={styles.dropDownItem}>
                  <Checkbox
                    icon={<UncheckedCheckboxIcon />}
                    checkedIcon={<CheckedCheckboxIcon />}
                    style={{marginRight: 8}}
                    checked={selected}
                  />
                  {option.name} / {option.posChassicNumber}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select station/posChassisNumber"
                  sx={searchAndSelect}
                />
              )}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default MainBackupsTopToolbar;
