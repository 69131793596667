import {api} from './api';
import {
  IGetAllBackupsResponse,
  IGetBackupsArgs,
  IGetMainBackupsResponse,
} from '../models/backups';
import {IGetStationsResponse} from '../models/stations';
import dayjs from 'dayjs';

export const backups = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getMainBackups: builder.query<IGetMainBackupsResponse, IGetBackupsArgs>({
        query: (mainBackupsParams) => {
          const params = new URLSearchParams();

          if (mainBackupsParams.search) {
            params.append('term', mainBackupsParams.search);
          }

          if (mainBackupsParams.pageNumber) {
            params.append('pageNumber', String(mainBackupsParams.pageNumber));
          }

          if (mainBackupsParams.pageSize) {
            params.append('pageSize', String(mainBackupsParams.pageSize));
          }

          if (mainBackupsParams.startDateTime) {
            params.append(
              'startDateTime',
              dayjs(mainBackupsParams.startDateTime)
                .subtract(6, 'hour')
                .format('YYYY-MM-DDTHH:mm:00.000') + 'Z',
            );
          }

          if (mainBackupsParams.endDateTime) {
            params.append(
              'endDateTime',
              dayjs(mainBackupsParams.endDateTime)
                .subtract(6, 'hour')
                .format('YYYY-MM-DDTHH:mm:00.000') + 'Z',
            );
          }

          if (mainBackupsParams.mainBackupFileType) {
            params.append('type', mainBackupsParams.mainBackupFileType.name);
          }

          if (mainBackupsParams?.companies?.length) {
            mainBackupsParams.companies.forEach((company) => {
              params.append('companyIds', company.id);
            });
          }
          if (mainBackupsParams?.branches?.length) {
            mainBackupsParams.branches.forEach((branch) => {
              params.append('branchIds', branch.id);
            });
          }
          if (mainBackupsParams?.stations?.length) {
            mainBackupsParams.stations.forEach((station) => {
              params.append('stationIds', station.id);
            });
          }

          if (
            mainBackupsParams.sortBy?.column &&
            mainBackupsParams.sortBy.direction
          ) {
            const column = mainBackupsParams.sortBy?.column;
            const direction = mainBackupsParams.sortBy.direction;
            params.append('orderBy', `${column}.${direction}`);
          }

          return {
            url: `backups/main-backup?${params.toString()}`,
            method: 'get',
          };
        },
      }),
      getAllBackups: builder.query<IGetAllBackupsResponse, IGetBackupsArgs>({
        query: (allBackupsParams) => {
          const params = new URLSearchParams();

          if (allBackupsParams.search) {
            params.append('term', allBackupsParams.search);
          }

          if (allBackupsParams.pageNumber) {
            params.append('pageNumber', String(allBackupsParams.pageNumber));
          }

          if (allBackupsParams.pageSize) {
            params.append('pageSize', String(allBackupsParams.pageSize));
          }

          if (allBackupsParams.startDateTime) {
            params.append(
              'startDateTime',
              dayjs(allBackupsParams.startDateTime)
                .subtract(6, 'hour')
                .format('YYYY-MM-DDTHH:mm:00.000') + 'Z',
            );
          }

          if (allBackupsParams.endDateTime) {
            params.append(
              'endDateTime',
              dayjs(allBackupsParams.endDateTime)
                .subtract(6, 'hour')
                .format('YYYY-MM-DDTHH:mm:00.000') + 'Z',
            );
          }

          // if (allBackupsParams.allBackupFileType) {
          //   params.append('type', allBackupsParams.allBackupFileType.name);
          // }

          if (allBackupsParams?.companies?.length) {
            allBackupsParams.companies.forEach((company) => {
              params.append('companyIds', company.id);
            });
          }
          if (allBackupsParams?.branches?.length) {
            allBackupsParams.branches.forEach((branch) => {
              params.append('branchIds', branch.id);
            });
          }
          if (allBackupsParams?.stations?.length) {
            allBackupsParams.stations.forEach((station) => {
              params.append('stationIds', station.id);
            });
          }

          if (
            allBackupsParams.sortBy?.column &&
            allBackupsParams.sortBy.direction
          ) {
            const column = allBackupsParams.sortBy?.column;
            const direction = allBackupsParams.sortBy.direction;
            params.append('orderBy', `${column}.${direction}`);
          }

          return {
            url: `backups/all-backups?${params.toString()}`,
            method: 'get',
          };
        },
      }),
      getStationForAllBackups: builder.query<IGetStationsResponse, void>({
        query: () => {
          return {
            url: 'stations?isDeleted=false',
            method: 'get',
          };
        },
      }),
      getBackupDownloadLinks: builder.query<string[], number[]>({
        query: (backupIds) => {
          const params = new URLSearchParams();

          if (backupIds.length) {
            backupIds.forEach((id: number) => {
              params.append('ids', String(id));
            });
          }

          return {
            url: `backups/download-backup-links?${params.toString()}`,
            method: 'get',
          };
        },
      }),
    };
  },
});

export const {
  useGetMainBackupsQuery,
  useGetAllBackupsQuery,
  useGetStationForAllBackupsQuery,
  useLazyGetBackupDownloadLinksQuery,
} = backups;
