export {ReactComponent as ArrowLeftIcon} from './arrowLeft.svg';
export {ReactComponent as DealersIcon} from './sidebarDealers.svg';
export {ReactComponent as CompanyOwnerIcon} from './sidebarCompanyOwner.svg';
export {ReactComponent as UsersIcon} from './sidebarUser.svg';
export {ReactComponent as StationIcon} from './sidebarStation.svg';
export {ReactComponent as CompaniesIcon} from './sidebarCompany.svg';
export {ReactComponent as BranchIcon} from './sidebarBranch.svg';
export {ReactComponent as BackupIcon} from './sidebarBackup.svg';
export {ReactComponent as ReportIcon} from './sidebarReport.svg';
export {ReactComponent as ManifestIcon} from './sidebarManifest.svg';
export {ReactComponent as SidebarCollapseDoubleLeft} from './sidebarCollapseDoubleLeft.svg';
export {ReactComponent as SidebarCollapseDoubleRight} from './sidebarCollapseDoubleRight.svg';
export {ReactComponent as CreateIcon} from './createIcon.svg';
export {ReactComponent as CreateIconBlue} from './createIconBlue.svg';
export {ReactComponent as MoreIcon} from './tableMoreDotsIcon.svg';
export {ReactComponent as DeleteIcon} from './deleteIcon.svg';
export {ReactComponent as EditIcon} from './editIcon.svg';
export {ReactComponent as SearchIcon} from './search.svg';
export {ReactComponent as SelectPopupIcon} from './selectPopup.svg';
export {ReactComponent as PaginationArrowRight} from './paginationArrowRight.svg';
export {ReactComponent as PaginationArrowLeft} from './paginationArrowLeft.svg';
export {ReactComponent as DefaultSortIcon} from './defaultSortIcon.svg';
export {ReactComponent as CloseIcon} from './closeIcon.svg';
export {ReactComponent as CloseIconNotModified} from './closeIconNotModified.svg';
export {ReactComponent as SuccessNotification} from './successNotification.svg';
export {ReactComponent as ErrorNotification} from './errorNotification.svg';
export {ReactComponent as ArrowAsc} from './arrowAsc.svg';
export {ReactComponent as ArrowDesc} from './arrowDesc.svg';
export {ReactComponent as PlusIcon} from './addEntityPlusIcon.svg';
export {ReactComponent as RecoverIcon} from './recover.svg';
export {ReactComponent as GoBackArrowButtonIcon} from './goBackButtonArrow.svg';
export {ReactComponent as RevertToDefaultManifestIcon} from './revertToDefaultManifest.svg';
export {ReactComponent as SaveIcon} from './save.svg';
export {ReactComponent as LogIcon} from './logsAndAlerts.svg';
export {ReactComponent as DownloadIcon} from './download.svg';
export {ReactComponent as UncheckedCheckboxIcon} from './uncheckedCheckbox.svg';
export {ReactComponent as CheckedCheckboxIcon} from './checkedCheckbox.svg';
export {ReactComponent as UnCheckCheckedCheckboxIcon} from './uncheckCheckedCheckbox.svg';
export {ReactComponent as CalendarIcon} from './calendar.svg';
export {ReactComponent as OpenInNewTab} from './openInNewTab.svg';
export {ReactComponent as FilterIcon} from './filter.svg';
export {ReactComponent as DatacollectorIcon} from './datacollector.svg';
export {ReactComponent as RefreshIcon} from './refresh.svg';
export {ReactComponent as GenerateExcelIcon} from './excel.svg';
export {ReactComponent as EnvelopeIcon} from './email.svg';
export {ReactComponent as PhoneIcon} from './phone.svg';
export {ReactComponent as InfoIcon} from './info.svg';
export {ReactComponent as PingIcon} from './ping.svg';
export {ReactComponent as WarningIcon} from './warning.svg';
export {ReactComponent as ChangePasswordIcon} from './changePassword.svg';
export {ReactComponent as LinkIcon} from './link.svg';
