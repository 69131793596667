import {api} from './api';
import {
  ICreateStationArgs,
  IGetStationById,
  IGetDefaultManifest,
  IGetStationsArgs,
  IGetStationsResponse,
  IEditStationArgs,
  StationsListItem,
  IGetStationsListArgs,
  IStationType,
} from '../models/stations';

export const stations = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getStationTypesList: builder.query<IStationType[], void>({
        query: () => ({
          url: `station-types`,
          method: 'get',
        }),
      }),
      getStationsList: builder.query<StationsListItem[], IGetStationsListArgs>({
        query: (args) => {
          const params = new URLSearchParams();

          if (args.branches && args.branches.length) {
            args.branches.forEach((branch) => {
              params.append('branchesIds', branch.id);
            });
          }
          if (args.companies && args.companies.length) {
            args.companies.forEach((c) => {
              params.append('companyIds', c.id);
            });
          }
          return {
            url: `stations/dictionary${params.toString()}`,
            method: 'get',
          };
        },
      }),
      getStations: builder.query<IGetStationsResponse, IGetStationsArgs>({
        query: (stationsParams) => {
          const params = new URLSearchParams();

          if (stationsParams.search) {
            params.append('term', stationsParams.search);
          }

          if (stationsParams.pageNumber) {
            params.append('pageNumber', String(stationsParams.pageNumber));
          }

          if (stationsParams.pageSize) {
            params.append('pageSize', String(stationsParams.pageSize));
          }

          if (stationsParams.status) {
            params.append(
              stationsParams.status.queryName,
              String(stationsParams.status.value),
            );
          }

          if (stationsParams.stationAttachedStatus) {
            params.append(
              stationsParams.stationAttachedStatus.queryName,
              String(stationsParams.stationAttachedStatus.value),
            );
          }

          if (stationsParams.status === null) {
            params.append('isDeleted', 'false');
          }

          if (
            stationsParams.sortBy?.column &&
            stationsParams.sortBy.direction
          ) {
            const column = stationsParams.sortBy?.column;
            const direction = stationsParams.sortBy.direction;
            params.append('orderBy', `${column}.${direction}`);
          }

          if (stationsParams?.companies?.length) {
            stationsParams.companies.forEach((company) => {
              params.append('companyIds', company.id);
            });
          }
          if (stationsParams?.branches?.length) {
            stationsParams.branches.forEach((branch) => {
              params.append('branchIds', branch.id);
            });
          }
          if (
            stationsParams.stationTypes &&
            stationsParams.stationTypes.length
          ) {
            stationsParams.stationTypes.forEach((t) => {
              params.append('stationTypes', `${t.id}`);
            });
          }

          return {
            url: `stations?${params.toString()}`,
            method: 'get',
          };
        },
        providesTags: () => ['Stations'],
      }),
      getDefaultManifest: builder.query<IGetDefaultManifest, number>({
        query: (stationTypeId) => {
          const params = new URLSearchParams();
          if (stationTypeId) {
            params.append('stationTypeId', String(stationTypeId));
          }
          return {
            url: `manifests/default-manifest?${params.toString()}`,
            method: 'get',
          };
        },
      }),
      createStation: builder.mutation<string, ICreateStationArgs>({
        query: (stationData) => {
          return {
            url: 'stations',
            method: 'post',
            data: stationData,
          };
        },
        invalidatesTags: (_, error) => (error ? [] : ['Stations']),
      }),
      resetHandshake: builder.mutation<void, string>({
        query: (stationId) => {
          return {
            url: `stations/reset-handshake?stationId=${stationId}`,
            method: 'post',
          };
        },
      }),
      getUnassignedCompaniesToAddToStation: builder.query<
        {id: string; name: string}[],
        string
      >({
        query: (stationId) => {
          return {
            url: `stations/unassigned-companies?stationId=${stationId}`,
            method: 'get',
          };
        },
      }),
      addCompanyToStation: builder.mutation<
        string,
        {stationId: string; companyId: string}
      >({
        query: (attachData) => {
          return {
            url: 'stations/attach-company',
            method: 'patch',
            data: {
              stationId: attachData.stationId,
              companyId: attachData.companyId,
            },
          };
        },
        invalidatesTags: (_, error) =>
          error ? [] : ['Stations', 'StationEdit'],
      }),
      getUnassignedBranchesToAddToStation: builder.query<
        {id: string; name: string}[],
        string
      >({
        query: (stationId) => {
          return {
            url: `stations/unassigned-branches?stationId=${stationId}`,
            method: 'get',
          };
        },
      }),
      addBranchToStation: builder.mutation<
        string,
        {stationId: string; branchId: string}
      >({
        query: (attachData) => {
          return {
            url: 'stations/attach-branch',
            method: 'patch',
            data: {
              stationId: attachData.stationId,
              branchId: attachData.branchId,
            },
          };
        },
        invalidatesTags: (_, error) =>
          error ? [] : ['Stations', 'StationEdit'],
      }),
      deleteBranchFromStation: builder.mutation<
        string,
        {stationId: string; branchId: string}
      >({
        query: (deAttachData) => {
          return {
            url: 'stations/de-attach-branch',
            method: 'patch',
            data: {
              stationId: deAttachData.stationId,
              branchId: deAttachData.branchId,
            },
          };
        },
        invalidatesTags: (_, error) =>
          error ? [] : ['StationEdit', 'Stations'],
      }),
      deleteCompanyFromStation: builder.mutation<
        string,
        {
          stationId: string;
          companyId: string;
        }
      >({
        query: (deAttachData) => {
          return {
            url: 'stations/de-attach-company',
            method: 'patch',
            data: {
              stationId: deAttachData.stationId,
              companyId: deAttachData.companyId,
            },
          };
        },
        invalidatesTags: (_, error) =>
          error ? [] : ['StationEdit', 'Stations'],
      }),
      getStationById: builder.query<IGetStationById, string>({
        query: (stationId) => {
          return {
            url: `stations/${stationId}`,
            method: 'get',
          };
        },
        providesTags: () => ['StationEdit'],
      }),
      editStation: builder.mutation<string, IEditStationArgs>({
        query: (stationData) => {
          return {
            url: 'stations',
            method: 'put',
            data: stationData,
          };
        },
        invalidatesTags: (_, error) =>
          error ? [] : ['Stations', 'StationEdit'],
      }),
      deleteStation: builder.mutation<string, string>({
        query: (stationId) => {
          return {
            url: 'stations',
            method: 'delete',
            data: {id: stationId},
          };
        },
        invalidatesTags: (_, error) => (error ? [] : ['Stations']),
      }),
      recoverStation: builder.mutation<string, string>({
        query: (stationId) => {
          return {
            url: `stations/restore-station?id=${stationId}`,
            method: 'post',
          };
        },
        invalidatesTags: (_, error) => (error ? [] : ['Stations']),
      }),
    };
  },
});

export const {
  useGetStationTypesListQuery,
  useGetStationsListQuery,
  useLazyGetStationsListQuery,
  useGetStationsQuery,
  useGetDefaultManifestQuery,
  useLazyGetDefaultManifestQuery,
  useCreateStationMutation,
  useResetHandshakeMutation,
  useLazyGetUnassignedCompaniesToAddToStationQuery,
  useAddCompanyToStationMutation,
  useLazyGetUnassignedBranchesToAddToStationQuery,
  useAddBranchToStationMutation,
  useLazyGetStationByIdQuery,
  useEditStationMutation,
  useDeleteStationMutation,
  useDeleteBranchFromStationMutation,
  useDeleteCompanyFromStationMutation,
  useRecoverStationMutation,
} = stations;
