import {FC} from 'react';
import {IMainBackupsProps} from 'store/models/backups';
import MainBackupsTopToolbar from './MainBackupsTopToolbar/MainBackupsTopToolbar';
import MainBackupsTable from './MainBackupsTable/MainBackupsTable';
import {useLocalStorage} from '../../../hooks';

const MainBackups: FC<IMainBackupsProps> = (props) => {
  const [
    isMainBackupsShowFilterButtonOpen,
    setIsMainBackupsShowFilterButtonOpen,
  ] = useLocalStorage('isMainBackupsShowFilterButtonOpen', true);

  const clearMainBackupsFilters = () => {
    props.setMainBackupsStartDate(null);
    props.setMainBackupsFinishDate(null);
    props.setSelectedStationsForMainBackups([]);
  };

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <MainBackupsTopToolbar
        search={props.search}
        setSearch={props.setSearch}
        mainBackupsStartDate={props.mainBackupsStartDate}
        setMainBackupsStartDate={props.setMainBackupsStartDate}
        mainBackupsFinishDate={props.mainBackupsFinishDate}
        setMainBackupsFinishDate={props.setMainBackupsFinishDate}
        mainBackupsPageSize={props.mainBackupsPageSize}
        setMainBackupsPageSize={props.setMainBackupsPageSize}
        stationsForMainBackups={props.stationsForMainBackups}
        selectedStationsForMainBackups={props.selectedStationsForMainBackups}
        setSelectedStationsForMainBackups={
          props.setSelectedStationsForMainBackups
        }
        isMainBackupsShowFilterButtonOpen={isMainBackupsShowFilterButtonOpen}
        setIsMainBackupsShowFilterButtonOpen={
          setIsMainBackupsShowFilterButtonOpen
        }
        metaData={props.metaData}
        clearMainBackupsFilters={clearMainBackupsFilters}
        backupsFileTypes={props.backupsFileTypes}
        mainBackupFileType={props.mainBackupFileType}
        setMainBackupFileType={props.setMainBackupFileType}
        companies={props.companies || []}
        selectedCompanies={props.selectedCompanies}
        setSelectedCompanies={props.setSelectedCompanies}
        branches={props.branches || []}
        selectedBranches={props.selectedBranches}
        setSelectedBranches={props.setSelectedBranches}
        setShouldSaveAllBackupsFileType={props.setShouldSaveAllBackupsFileType}
      />
      <div
        style={{
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        <MainBackupsTable
          modifiedMainBackups={props.modifiedMainBackups}
          setModifiedMainBackups={props.setModifiedMainBackups}
          mainBackupSortConfig={props.mainBackupSortConfig}
          mainBackupHandleColumnClick={props.mainBackupHandleColumnClick}
          mainBackupsDebouncedSearch={props.mainBackupsDebouncedSearch}
          metaData={props.metaData}
          downloadFileHandler={props.downloadFileHandler}
          openInNewTabHandler={props.openInNewTabHandler}
        />
      </div>
    </div>
  );
};

export default MainBackups;
