import {api} from './api';
import {
  ICompanyCreateOwnerArgs,
  ICompanyGetOwnersArgs,
  ICompanyGetOwnersServerResponse,
  ICompanyOwnerEditByIdArgs,
  ICompanyOwnerGetByIdServerResponse,
} from '../models/companyOwners';

export const companyOwners = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getCompanyOwners: builder.query<
        ICompanyGetOwnersServerResponse,
        ICompanyGetOwnersArgs
      >({
        query: (companyOwnerParams) => {
          const params = new URLSearchParams();

          if (companyOwnerParams.search) {
            params.append('term', companyOwnerParams.search);
          }

          if (companyOwnerParams.status) {
            params.append(
              companyOwnerParams.status.queryName,
              String(companyOwnerParams.status.value),
            );
          }

          if (companyOwnerParams.pageNumber) {
            params.append('pageNumber', String(companyOwnerParams.pageNumber));
          }

          if (companyOwnerParams.pageSize) {
            params.append('pageSize', String(companyOwnerParams.pageSize));
          }

          if (
            companyOwnerParams.sortBy?.column &&
            companyOwnerParams.sortBy.direction
          ) {
            const column = companyOwnerParams.sortBy?.column;
            const direction = companyOwnerParams.sortBy.direction;
            params.append('orderBy', `${column}.${direction}`);
          }

          return {
            url: `owners?${params.toString()}`,
            method: 'get',
          };
        },
        providesTags: () => ['CompanyOwners'],
      }),
      getCompanyOwnerById: builder.query<
        ICompanyOwnerGetByIdServerResponse,
        string
      >({
        query: (companyOwnerId) => {
          return {
            url: `owners/${companyOwnerId}`,
            method: 'get',
          };
        },
        providesTags: () => ['CompanyOwnersEdit'],
      }),
      addCompanyToCompanyOwner: builder.mutation<
        string,
        {ownerId: string; companyId: string}
      >({
        query: (companyOwnerData) => {
          return {
            url: 'owners/attach',
            method: 'patch',
            data: companyOwnerData,
          };
        },
        invalidatesTags: (_, error) => (error ? [] : ['CompanyOwnersEdit']),
      }),
      createCompanyOwner: builder.mutation<string, ICompanyCreateOwnerArgs>({
        query: (companyOwnerData) => {
          return {
            url: 'owners',
            method: 'post',
            data: companyOwnerData,
          };
        },
        invalidatesTags: ['CompanyOwners'],
      }),
      editCompanyOwner: builder.mutation<string, ICompanyOwnerEditByIdArgs>({
        query: (companyOwnerData) => {
          return {
            url: 'owners',
            method: 'put',
            data: companyOwnerData,
          };
        },
        invalidatesTags: ['CompanyOwners'],
      }),
      deleteCompanyFromCompanyOwner: builder.mutation<
        string,
        {
          ownerId: string;
          companyId: string;
        }
      >({
        query: (companyOwnerData) => {
          return {
            url: 'owners/de-attach',
            method: 'patch',
            data: companyOwnerData,
          };
        },
      }),
      deleteCompanyOwner: builder.mutation<string, string>({
        query: (companyOwnerId) => {
          return {
            url: 'owners',
            method: 'delete',
            data: {userId: companyOwnerId},
          };
        },
        invalidatesTags: ['CompanyOwners'],
      }),
      companyOwnerResetPassword: builder.mutation<string, string>({
        query: (companyOwnerId) => {
          return {
            url: 'owners/reset-password',
            method: 'post',
            data: {id: companyOwnerId},
          };
        },
      }),
      recoverCompanyOwner: builder.mutation<string, string>({
        query: (companyOwnerId) => {
          return {
            url: 'owners/restore',
            method: 'post',
            data: {userId: companyOwnerId},
          };
        },
        invalidatesTags: ['CompanyOwners'],
      }),
    };
  },
});

export const {
  useGetCompanyOwnersQuery,
  useLazyGetCompanyOwnerByIdQuery,
  useAddCompanyToCompanyOwnerMutation,
  useCreateCompanyOwnerMutation,
  useEditCompanyOwnerMutation,
  useDeleteCompanyFromCompanyOwnerMutation,
  useDeleteCompanyOwnerMutation,
  useCompanyOwnerResetPasswordMutation,
  useRecoverCompanyOwnerMutation,
} = companyOwners;
